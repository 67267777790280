import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getConfig } from "../../utils/auth";

export default function StaffWorkingTimeLog({ venueId, staffId, refreshLogs }) {
  const [timeLogs, setTimeLogs] = useState([]);
  const [updatedFields, setUpdatedFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [staffDetails, setStaffDetails] = useState(null); // Assuming staffDetails.rate is part of this object

  useEffect(() => {
    fetchTimeLogs();
    fetchStaffDetails();
  }, [venueId, staffId, dateRange, refreshLogs]);

  const fetchTimeLogs = async () => {
    try {
      const config = getConfig();
      const startDate = dateRange[0]?.toISOString();
      const endDate = dateRange[1]?.toISOString();
      const { data } = await axios.get(`/staff/ttl`, {
        params: { venueId, staffId, startDate, endDate },
        ...config,
      });
      setTimeLogs(data.data.timeLogs);
    } catch (error) {
      console.error("Error fetching time logs:", error);
      setError("Failed to fetch time logs. Please try again.");
    }
  };

  const fetchStaffDetails = async () => {
    try {
      const config = getConfig();
      const { data } = await axios.get(`/staff/single`, {
        params: { venueId, staffId },
        ...config,
      });
      if (data.data.staffs) {
        setStaffDetails(data.data.staffs);
      }
    } catch (error) {
      console.error("Error fetching staff details:", error);
      setError("Failed to fetch staff details. Please try again.");
    }
  };

  const handleDateChange = (field, value, logId) => {
    console.log(76, field);
    if (field === "paidAmount") {
      let updateValue = value.replace(/,/g, ""); // Remove existing commas
      updateValue = Number(updateValue).toLocaleString(); // Format with commas
      console.log(76, updateValue);
      setUpdatedFields((prev) => ({
        ...prev,
        [logId]: {
          ...prev[logId],
          [field]: updateValue,
        },
      }));
    } else {
      console.log(76, "double", {
        [logId]: {
          [field]: value,
        },
      });
      setUpdatedFields((prev) => ({
        ...prev,
        [logId]: {
          ...prev[logId],
          [field]: value,
        },
      }));
    }
  };

  const handleUpdate = async (logId) => {
    setLoading(true);
    try {
      const fieldsToUpdate = updatedFields[logId];
      if (fieldsToUpdate.paidAmount) {
        fieldsToUpdate.paidAmount = fieldsToUpdate.paidAmount.replace(/,/g, "");
      }
      const config = getConfig();
      await axios.put(
        `/venue/ttl?venueId=${venueId}`,
        {
          venueId,
          staffId,
          logId,
          ...fieldsToUpdate,
        },
        config
      );
      setSuccess(true);
      fetchTimeLogs();
    } catch (error) {
      console.error("Error updating time log:", error);
      setError("Failed to update time log. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
    setError(null);
  };

  const calculateHoursWorked = (timeIn, specifiedTimeOut, working) => {
    if (!working) return "0 hours";

    const start = new Date(timeIn);
    const end = specifiedTimeOut ? new Date(specifiedTimeOut) : new Date();
    console.log(118, timeIn, specifiedTimeOut, start, end);
    const hours = (end - start) / (1000 * 60 * 60); // Calculate total hours

    // Separate hours and minutes
    const hoursPart = Math.floor(hours);
    const minutesPart = Math.round((hours - hoursPart) * 60);

    // Format hours and minutes as X.Y hours
    if (hoursPart === 0 && minutesPart === 0) {
      return "0 hours";
    } else if (minutesPart === 0) {
      return `${hoursPart} hours`;
    } else {
      return `${hoursPart}.${minutesPart} hours`;
    }
  };

  const calculateEarnings = (hoursWorked) => {
    if (!staffDetails || !staffDetails.rate) return 0;
    return parseFloat(hoursWorked) * parseFloat(staffDetails.rate);
  };

  const calculateTotalHoursWorked = () => {
    let totalHours = 0;
    timeLogs.forEach((log) => {
      if (log.working) {
        const start = new Date(log.timeIn);
        const end = log.specifiedTimeOut
          ? new Date(log.specifiedTimeOut)
          : new Date();
        totalHours += (end - start) / (1000 * 60 * 60);
      }
    });
    return totalHours;
  };

  const calculateTotalEarnings = () => {
    let totalEarnings = 0;
    timeLogs.forEach((log) => {
      if (log.working) {
        const hoursWorked = calculateHoursWorked(
          updatedFields[log._id]?.timeIn || log.timeIn,
          updatedFields[log._id]?.specifiedTimeOut || log.specifiedTimeOut,
          log.working
        );
        totalEarnings += calculateEarnings(hoursWorked);
      }
    });
    return totalEarnings;
  };

  const calculateTotalEarningsNotPaid = () => {
    let totalEarnings = 0;
    timeLogs.forEach((log) => {
      if (log.working && !log.paidOut) {
        const hoursWorked = calculateHoursWorked(
          updatedFields[log._id]?.timeIn || log.timeIn,
          updatedFields[log._id]?.specifiedTimeOut || log.specifiedTimeOut,
          log.working
        );
        totalEarnings += calculateEarnings(hoursWorked);
      }
    });
    return totalEarnings;
  };
  const calculateTotalPaid = () => {
    let totalPaid = 0;
    timeLogs.forEach((log) => {
      if (log.paidAmount && !log.paidOut) {
        totalPaid += parseFloat(log.paidAmount);
      }
    });
    let totalEarnings = calculateTotalEarningsNotPaid();
    return totalEarnings - totalPaid;
  };
  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Staff Working Time Logs
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={dayjs(dateRange[0])}
          onChange={(newValue) => setDateRange([newValue, dateRange[1]])}
        />
        <DatePicker
          label="End Date"
          value={dayjs(dateRange[1])}
          onChange={(newValue) => setDateRange([dateRange[0], newValue])}
        />
      </LocalizationProvider>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Player Name</TableCell>
              <TableCell>Homeworld</TableCell>
              <TableCell>Time In</TableCell>
              <TableCell>Specified Time Out</TableCell>
              <TableCell>Hours Worked</TableCell>
              <TableCell>Working</TableCell>
              <TableCell>Booked</TableCell>
              <TableCell>Paid Out</TableCell>
              <TableCell>Earnings</TableCell>
              <TableCell>Paid Amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeLogs.map((log) => (
              <TableRow key={log._id}>
                <TableCell>{log.playerName}</TableCell>
                <TableCell>{log.homeworld}</TableCell>
                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={dayjs(
                        updatedFields[log._id]?.timeIn || log.timeIn
                      )}
                      onChange={(e) =>
                        handleDateChange("timeIn", e.$d, log._id)
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </TableCell>
                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={dayjs(
                        updatedFields[log._id]?.specifiedTimeOut ||
                          log.specifiedTimeOut
                      )}
                      onChange={(newValue) =>
                        handleDateChange(
                          "specifiedTimeOut",
                          newValue.$d,
                          log._id
                        )
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </TableCell>
                <TableCell>
                  {calculateHoursWorked(
                    updatedFields[log._id]?.timeIn || log.timeIn,
                    updatedFields[log._id]?.specifiedTimeOut ||
                      log.specifiedTimeOut,
                    log.working
                  )}
                </TableCell>
                <TableCell>
                  <Select
                    labelId="working"
                    label="working"
                    value={
                      updatedFields[log._id]
                        ? updatedFields[log._id].working
                          ? updatedFields[log._id].working
                          : log.working
                        : log.working
                    }
                    onChange={(e) =>
                      handleDateChange("working", e.target.value, log._id)
                    }
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    labelId="booked"
                    label="booked"
                    value={
                      updatedFields[log._id]
                        ? updatedFields[log._id].booked
                          ? updatedFields[log._id].booked
                          : log.booked
                        : log.booked
                    }
                    onChange={(e) =>
                      handleDateChange("booked", e.target.value, log._id)
                    }
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    labelId="Paid Out"
                    label="Paid Out"
                    value={
                      updatedFields[log._id]
                        ? updatedFields[log._id].paidOut
                          ? updatedFields[log._id].paidOut
                          : log.paidOut
                        : log.paidOut
                    }
                    onChange={(e) =>
                      handleDateChange("paidOut", e.target.value, log._id)
                    }
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  {formatNumberWithCommas(
                    calculateEarnings(
                      calculateHoursWorked(
                        updatedFields[log._id]?.timeIn || log.timeIn,
                        updatedFields[log._id]?.specifiedTimeOut ||
                          log.specifiedTimeOut,
                        log.working
                      )
                    )
                  )}
                </TableCell>
                <TableCell>
                  <TextField
                    label="Paid Amount"
                    value={
                      updatedFields[log._id]
                        ? updatedFields[log._id].paidAmount
                          ? updatedFields[log._id].paidAmount
                          : log.paidAmount
                        : log.paidAmount
                    }
                    onChange={(e) =>
                      handleDateChange("paidAmount", e.target.value, log._id)
                    }
                    fullWidth
                    margin="normal"
                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ position: "relative" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate(log._id)}
                      disabled={loading}
                    >
                      Update
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "primary.main",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell colSpan={5} align="right">
                <Typography variant="subtitle1">Total Hours Worked:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {calculateTotalHoursWorked().toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Total Earnings:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {formatNumberWithCommas(calculateTotalEarnings().toFixed(2))}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">Total Left To Pay:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {formatNumberWithCommas(calculateTotalPaid().toFixed(2))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Time log updated successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
}
