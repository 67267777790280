import React, { useEffect, useState } from "react";
import { getConfig } from "../utils/auth";
import axios from "axios";
import ListVenues from "../components/venue/listVenue";
import VenueForm from "../components/venue/venueForm";
import { Typography, Container, Card, CardContent } from "@mui/material"; // Importing Card and CardContent from Material-UI
import AddStaff from "../components/venue/venueStaffAdd";
import StaffManagment from "../components/venue/venueStaffManagement";

export default function App(props) {
  const [venues, setVenues] = useState([]);

  useEffect(() => {
    getVenues();
  }, []);

  const getVenues = async () => {
    try {
      setVenues([]);
      let config = getConfig();
      let { data } = await axios.get("/venue", config);
      setVenues(data.data.venues);
      console.log("venues", data.data.venues);
    } catch (error) {
      console.error("Error fetching venues:", error);
    }
  };

  return (
    <div className="App">
      <Container maxWidth="md" sx={{ mt: 4, mb: 4, marginBottom: 25 }}>
        <Card sx={{ padding: "10px" }}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Owned Venues
            </Typography>
            <ListVenues venues={venues} />
          </CardContent>
        </Card>
      </Container>
      <Container maxWidth="md" sx={{ mt: 4, mb: 4, marginBottom: 25 }}>
        <Card sx={{ padding: "10px" }}>
          <CardContent>
            <VenueForm venueAdded={getVenues} />
          </CardContent>
        </Card>
      </Container>
      {venues.length ? (
        <>
          <Container maxWidth="md" sx={{ mt: 4, mb: 4, marginBottom: 25 }}>
            <Card sx={{ padding: "10px" }}>
              <CardContent>
                <StaffManagment
                  housingId={venues[0]?.housingId}
                  venueId={venues[0]?._id}
                />
              </CardContent>
            </Card>
          </Container>
          <Container maxWidth="md" sx={{ mt: 4, mb: 4, marginBottom: 25 }}>
            <Card sx={{ padding: "10px" }}>
              <CardContent>
                <AddStaff
                  venueId={venues[0]?._id}
                  venueName={venues[0]?.name}
                  updateStaff={getVenues}
                />
              </CardContent>
            </Card>
          </Container>
        </>
      ) : null}
    </div>
  );
}
