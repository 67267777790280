import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { getConfig } from "../../utils/auth";

export default function UpdateStaffTimeLogForm({
  venueId,
  housingId,
  staffId,
  refreshLogs,
}) {
  const [staffDetails, setStaffDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [updatedFields, setUpdatedFields] = useState({
    working: false,
    booked: false,
    paidOut: false,
    paidOutTotal: 0,
    timeIn: null, // Initialize with null
    specifiedTimeOut: null, // Initialize with null
  });

  useEffect(() => {
    fetchStaffDetails();
  }, [venueId, housingId, staffId]);

  const fetchStaffDetails = async () => {
    try {
      const config = getConfig();
      const { data } = await axios.get(`/staff/single`, {
        params: { venueId, staffId },
        ...config,
      });
      if (data.data.staffs) {
        setStaffDetails(data.data.staffs);
      }
    } catch (error) {
      console.error("Error fetching staff details:", error);
      setError("Failed to fetch staff details. Please try again.");
    }
  };

  const handleInputChange = (field, value) => {
    if (field === "paidAmount") {
      let updateValue = value.replace(/,/g, ""); // Remove existing commas
      updateValue = Number(updateValue).toLocaleString(); // Format with commas

      setUpdatedFields((prev) => ({
        ...prev,
        [field]: updateValue,
      }));
    } else {
      setUpdatedFields((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const config = getConfig();
      const updatedStaff = {
        email: staffDetails.email,
        name: staffDetails.name,
        playerName: staffDetails.playerName,
        homeworld: staffDetails.homeworld,
        ...updatedFields,
      };
      updatedStaff.paidAmount = updatedFields.paidAmount.replace(/,/g, "");
      await axios.post(
        `/venue/ttl?venueId=${venueId}`,
        {
          venueId,
          housingId,
          staffId,
          ...updatedStaff,
        },
        config
      );
      setSuccess(true);
      fetchStaffDetails();
      refreshLogs();
    } catch (error) {
      console.error("Error updating staff time log:", error);
      setError("Failed to update staff time log. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
    setError(null);
  };

  if (!staffDetails) return null;

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Update Staff Time Log
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Email"
          value={staffDetails.email}
          disabled
          fullWidth
          margin="normal"
        />
        <TextField
          label="Name"
          value={staffDetails.name}
          disabled
          fullWidth
          margin="normal"
        />
        <TextField
          label="Player Name"
          value={staffDetails.playerName}
          disabled
          fullWidth
          margin="normal"
        />
        <TextField
          label="Homeworld"
          value={staffDetails.homeworld}
          disabled
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth>
          <InputLabel id="Workingl">Working</InputLabel>
          <Select
            labelId="Workingl"
            id="Working"
            label="Working"
            value={updatedFields.working}
            onChange={(e) => handleInputChange("working", e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="Booked">Booked</InputLabel>
          <Select
            labelId="Booked"
            label="Booked"
            value={updatedFields.booked.toString()}
            onChange={(e) => handleInputChange("booked", e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="Paid Out">Paid Out</InputLabel>
          <Select
            labelId="Paid Out"
            label="Paid Out"
            value={updatedFields.paidOut}
            onChange={(e) => handleInputChange("paidOut", e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Paid Amount"
          value={updatedFields.paidAmount}
          onChange={(e) => handleInputChange("paidAmount", e.target.value)}
          fullWidth
          margin="normal"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Time In"
            value={updatedFields.timeIn || null}
            onChange={(value) => handleInputChange("timeIn", value)}
            renderInput={(params) => <TextField {...params} />}
            fullWidth
            margin="normal"
          />
          <DateTimePicker
            label="Specified Time Out"
            value={updatedFields.specifiedTimeOut || null}
            onChange={(value) => handleInputChange("specifiedTimeOut", value)}
            renderInput={(params) => <TextField {...params} />}
            fullWidth
            margin="normal"
          />
        </LocalizationProvider>
        <Box sx={{ mt: 2, position: "relative" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Update Staff Time Log
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: "primary.main",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Box>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Staff time log updated successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
}
