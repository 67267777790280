import React, { useState } from "react";
import {
  Container,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  CssBaseline,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

function sendRecoveryEmail(email) {
  // Handle sending recovery email logic here
  console.log("Sending recovery email to:", email);
  // Simulate successful submission
  return true;
}

function resetPassword(recoveryCode, newPassword) {
  // Handle password reset logic here
  console.log("Resetting password with recovery code:", recoveryCode);
  // Simulate successful password reset
  return true;
}

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [recoveryCode, setRecoveryCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [submittedPassword, setSubmittedPassword] = useState(false);

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    if (sendRecoveryEmail(email)) {
      setSubmittedEmail(true);
    }
  };

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    if (resetPassword(recoveryCode, newPassword)) {
      setSubmittedPassword(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {submittedEmail && !submittedPassword
            ? "Reset Password"
            : "Forgot Password"}
        </Typography>
        {!submittedEmail ? (
          <Box
            component="form"
            onSubmit={handleEmailSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send Recovery Email
            </Button>
          </Box>
        ) : !submittedPassword ? (
          <Box
            component="form"
            onSubmit={handlePasswordSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="recoveryCode"
              label="Recovery Code"
              name="recoveryCode"
              autoFocus
              value={recoveryCode}
              onChange={(e) => setRecoveryCode(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
          </Box>
        ) : (
          <Typography variant="body1" sx={{ mt: 2 }}>
            Password has been reset successfully!
          </Typography>
        )}
      </Box>
    </Container>
  );
}
