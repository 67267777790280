import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const VenuesAccordion = ({ venues }) => {
  return (
    <div>
      {venues.map((venue, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{venue.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <strong>Staff:</strong> {venue.staff ? venue.staff.length : 0}
            </Typography>
            <Typography variant="body1">
              <strong>Housing ID:</strong> {venue.housingId || "Not available"}
            </Typography>
            <Typography variant="body1">
              <strong>Date Created:</strong>{" "}
              {venue.dateCreated
                ? new Date(venue.dateCreated).toLocaleDateString()
                : "Not available"}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default VenuesAccordion;
