import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { getConfig } from "../../utils/auth";

export default function CreateStaffForm({ venueId, updateStaff, venueName }) {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    playerName: "",
    homeworld: "",
    venueDisplayName: "",
    rate: 0,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "rate") {
      formattedValue = value.replace(/,/g, ""); // Remove existing commas
      formattedValue = Number(formattedValue).toLocaleString(); // Format with commas
    }

    setFormData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const newStaff = {
        ...formData,
        venueId,
        venueDisplayName: venueName,
        payroll: [],
        totalPaid: 0,
        totalUnpaid: 0,
        workDates: [],
        rate: formData.rate.replace(/,/g, ""), // Remove commas before saving
      };
      const config = getConfig();
      await axios.post(`/staff?venueId=${venueId}`, newStaff, config);
      setSuccess(true);
      setFormData({
        email: "",
        name: "",
        playerName: "",
        homeworld: "",
        rate: 0,
      });
      updateStaff();
    } catch (error) {
      console.error("Error creating staff:", error);
      setError("Failed to create staff. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
    setError(null);
  };

  return (
    <Container maxWidth="sm">
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Create New Staff Member
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Player Name"
              name="playerName"
              value={formData.playerName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Homeworld"
              name="homeworld"
              value={formData.homeworld}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Gil Per Hour"
              name="rate"
              value={formData.rate}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />
            <Box sx={{ mt: 2, position: "relative" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Create Staff
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "primary.main",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Staff member created successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
}
