import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Container,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { getConfig } from "../../utils/auth";
import { useNavigate } from "react-router-dom";

export default function StaffMemberDetails({ venueId, housingId }) {
  const [staff, setStaff] = useState([]);
  const [updatedFields, setUpdatedFields] = useState({});
  const [loading, setLoading] = useState({});
  const [success, setSuccess] = useState(false);
  const [successName, setSuccessName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetchStaff();
  }, [venueId]);

  const fetchStaff = async () => {
    try {
      const config = getConfig();
      const { data } = await axios.get(`/staff?venueId=${venueId}`, config);
      setStaff(data.data.staffs);
    } catch (error) {
      console.error("Error fetching staff:", error);
    }
  };

  const handleInputChange = (e, staffId) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "rate") {
      formattedValue = value.replace(/,/g, ""); // Remove existing commas
      formattedValue = Number(formattedValue).toLocaleString(); // Format with commas
    }

    setUpdatedFields((prev) => ({
      ...prev,
      [staffId]: {
        ...prev[staffId],
        [name]: formattedValue,
      },
    }));
  };

  const handleUpdate = async (staffId) => {
    try {
      setLoading((prev) => ({ ...prev, [staffId]: true }));
      const fieldsToUpdate = updatedFields[staffId];
      const config = getConfig();
      await axios.put(
        `/staff?venueId=${venueId}`,
        {
          id: staffId,
          ...fieldsToUpdate,
          rate: fieldsToUpdate.rate
            ? fieldsToUpdate.rate.replace(/,/g, "")
            : undefined, // Remove commas before saving
        },
        config
      );
      setSuccessName(staff.find((s) => s._id === staffId).name);
      setSuccess(true);
      fetchStaff();
    } catch (error) {
      console.error("Error updating staff:", error);
    } finally {
      setLoading((prev) => ({ ...prev, [staffId]: false }));
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Staff Management
      </Typography>
      {staff.map((member) => (
        <Card key={member._id} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h5">
              {member.name || "Unnamed Staff"}
            </Typography>
            <TextField
              label="Email"
              name="email"
              defaultValue={member.email}
              onChange={(e) => handleInputChange(e, member._id)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Name"
              name="name"
              defaultValue={member.name}
              onChange={(e) => handleInputChange(e, member._id)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Player Name"
              name="playerName"
              defaultValue={member.playerName}
              onChange={(e) => handleInputChange(e, member._id)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Homeworld"
              name="homeworld"
              defaultValue={member.homeworld}
              onChange={(e) => handleInputChange(e, member._id)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Gil per hour"
              name="rate"
              value={
                updatedFields[member._id]?.rate ?? member.rate.toLocaleString()
              }
              onChange={(e) => handleInputChange(e, member._id)}
              fullWidth
              margin="normal"
              required
            />

            <Typography variant="body1">
              Venue Display Name: {member.venueDisplayName}
            </Typography>
            <Typography variant="body1">
              Total Paid: {member.totalPaid.toLocaleString()}
            </Typography>
            <Typography variant="body1">
              Total Unpaid: {member.totalUnpaid.toLocaleString()}
            </Typography>
            <Typography variant="body1">
              Date Created: {new Date(member.dateCreated).toLocaleDateString()}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => console.log("View Payroll", member.payroll)}
            >
              View Payroll
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                navigate(
                  `/staff/timesheet?venueId=${venueId}&staffId=${member._id}&housingId=${housingId}`
                )
              }
            >
              View Work Dates
            </Button>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdate(member._id)}
                disabled={loading[member._id]}
              >
                {loading[member._id] ? (
                  <>
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                    Updating
                  </>
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </CardContent>
        </Card>
      ))}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successName} updated successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
}
