import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import AddTimeLog from "../components/staff/addTimeLogStaff";
import TimeLog from "../components/staff/timeLogStaff";

export default function App(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const venueId = queryParams.get("venueId");
  const staffId = queryParams.get("staffId");
  const housingId = queryParams.get("housingId");
  const [refresh, setRefresh] = useState(false);

  return (
    <div className="App">
      <TimeLog
        venueId={venueId}
        staffId={staffId}
        housingId={housingId}
        refreshLogs={refresh}
        {...props}
      />
      <AddTimeLog
        venueId={venueId}
        staffId={staffId}
        housingId={housingId}
        refreshLogs={() => setRefresh(!refresh)}
        {...props}
      />
    </div>
  );
}
