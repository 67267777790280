import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const isBrowser = () => typeof window !== "undefined";

export const getUser = () => {
  if (isBrowser() && window.localStorage.getItem("gardenUser")) {
    try {
      return jwtDecode(
        JSON.parse(window.localStorage.getItem("gardenUser")).token
      );
    } catch (e) {
      return false;
    }
  }
  return false;
};
export const getToken = () => {
  if (isBrowser() && window.localStorage.getItem("gardenUser")) {
    try {
      return JSON.parse(window.localStorage.getItem("gardenUser")).token;
    } catch (e) {
      return false;
    }
  }
  return false;
};

const setUser = (user) =>
  window.localStorage.setItem("gardenUser", JSON.stringify(user));

export const handleLogin = async ({ email, password }) => {
  console.log(email + " " + password);
  return await axios
    .post("/auth/login", {
      email: email,
      password: password,
    })
    .then((res) => {
      if (res.data.status === 403) {
        alert(res.data.message);
        console.error(res.data.message);
      } else if (res.status === 200) {
        setUser({ token: res.data.token });
        return true;
      } else {
        const error = new Error(res.error);
        console.log(error);
        return false;
      }
    })
    .catch((err) => {
      console.error(err);
      console.log(err);
      return false;
    });
};
export const handleLoginToken = async () => {
  let token = getToken();
  return await axios
    // .post("http://localhost:3000/auth/login", {
    .post("/auth/accesstoken", {
      access_token: token,
    })
    .then((res) => {
      if (res.data.status === 403) {
        alert(res.data.message);
        console.error(res.data.message);
      } else if (res.status === 200) {
        setUser({ token: res.data.token });
        return true;
      } else {
        const error = new Error(res.error);
        console.log(error);
        return false;
      }
    })
    .catch((err) => {
      console.error(err);
      console.log(err);
      return false;
    });
};
export const handleRegister = async ({ email, password, name }) => {
  console.log(email + " " + password);
  return await axios
    .post("/auth/register", {
      name,
      password,
      email,
    })
    .then((res) => {
      if (res.status === 200) {
        console.log("created user");
        return true;
      } else {
        const error = new Error(res.error);
        throw console.log(error);
        return false;
      }
    })
    .catch((err) => {
      try {
        return false;
        console.error(err);
        console.log(err);
      } catch (e) {
        console.log(err);
        return false;
      }
    });
};

export const isLoggedIn = () => {
  const user = getUser();
  console.log(user);
  if (user) {
    return !!user.user.id;
  } else {
    return user;
  }
};
export const isSubscribed = async () => {
  const token = getToken();
  let gardenConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  if (token) {
    let { data } = await axios.get("/store/personal", gardenConfig);
    console.log(166, data, data.subscribed);

    if (data.subscribed) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const logout = (callback) => {
  setUser({});
  callback();
};
export const getConfig = () => {
  const token = getToken();
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
};
