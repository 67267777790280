import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./styles.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from "./components/nav/navigation";
import Footer from "./components/nav/footer";
import axios from "axios";
import Home from "./App.js";
import Dashboard from "./pages/home";
import { getToken } from "./utils/auth";
import PrivateRoute from "./utils/privateRoute";
import Recovery from "./pages/recovery";
import Register from "./pages/register";
import TimeSheetStaff from "./pages/timesheetStaff";
axios.defaults.baseURL = "https://api.lotusvenuemanagement.info";
//axios.defaults.baseURL = "http://localhost:6969";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const tempToken = getToken();
    if (tempToken) {
      setIsLoggedIn(true);
    }
    setLoading(false);
  }, []);
  const updateLoggedIn = (loggedin) => {
    setIsLoggedIn(loggedin);
    return;
  };
  return loading ? (
    <></>
  ) : (
    <Router>
      <div className="App">
        <Nav />
        <div className="container">
          <Routes>
            <Route
              path="/"
              element={<Home updateLoggedIn={updateLoggedIn} />}
            />
            <Route path="/forgot-password" element={<Recovery />} />
            <Route path="/register" element={<Register />} />
            <Route
              exact
              path="/home"
              element={<PrivateRoute isLoggedIn={isLoggedIn} />}
            >
              <Route exact path="/home" element={<Dashboard />} />
            </Route>
            <Route
              exact
              path="/staff/timesheet"
              element={<PrivateRoute isLoggedIn={isLoggedIn} />}
            >
              <Route
                exact
                path="/staff/timesheet"
                element={<TimeSheetStaff />}
              />
            </Route>
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
