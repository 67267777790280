// VenueForm.js
import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography } from "@mui/material";
import { getConfig } from "../../utils/auth";

const VenueForm = (props) => {
  const [venueName, setVenueName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setError("");
    let config = getConfig();
    try {
      const { data } = await axios.post(
        "/venue",
        {
          name: venueName,
          staff: [],
        },
        config
      );
      console.log("Venue added successfully:", data.data);
      alert("venue added");
      console.log(data);
      props.venueAdded();
      // Optionally, you can redirect or show a success message here
      setVenueName(""); // Clear the input field after successful submission
    } catch (error) {
      console.error("Error adding venue:", error.response.data.error);
      setError(`Failed to add venue. Error: ${error.response.data.error}`); // Display error message
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = (event) => {
    setVenueName(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Add New Venue
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          label="Venue Name"
          variant="outlined"
          value={venueName}
          onChange={handleChange}
          fullWidth
          required
          disabled={submitting}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting || !venueName}
          sx={{ ml: 2 }}
        >
          {submitting ? "Adding..." : "Add Venue"}
        </Button>
      </Box>
      {error && (
        <Typography variant="body2" color="error" mt={1}>
          {error}
        </Typography>
      )}
    </form>
  );
};

export default VenueForm;
